<template>
  <div class="ChargingGunStatus">
    <div class="echart">
      <img src="../../../assets/images/round-bg.png" />
      <div ref="initChart" class="chart-box"></div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import { onMounted, defineProps, watch, ref, nextTick, computed } from "vue";

const data = computed(() => {
  return [
    {
      name: "充电",
      value: props.data[0],
      itemStyle: { color: "#00FFAA" },
    },
    {
      name: "待机",
      value: props.data[1],
      itemStyle: { color: "#FDE9A6" },
    },
    {
      name: "故障",
      value: props.data[2],
      itemStyle: { color: "#38EBFF" },
    },
    {
      name: "离线",
      value: props.data[3],
      itemStyle: { color: "#FF6969" },
    },
    {
      name: "已插枪",
      value: props.data[4],
      itemStyle: { color: "#646BFE" },
    },
    {
      name: "已完成",
      value: props.data[5],
      itemStyle: { color: "#2386FF" },
    },
  ];
});

const props = defineProps({
  data: {
    type: Array,
    default: () => {
      return [0, 0, 0, 0];
    },
  },
});

watch(
    () => props.data,
    () => {
      nextTick(() => {
        chartInit();
      });
    }
);

const initChart = ref();

onMounted(() => {
  chartInit();
});

const chartInit = () => {
  const getPie = (pieData) => {
    let legendData = [];
    let seriesData = pieData.map(item => {
      legendData.push(item.name);
      return {
        name: item.name,
        value: item.value,
        itemStyle: item.itemStyle,
      };
    });

    let option = {
      tooltip: {
        backgroundColor: "rgba(18, 26, 45, 0.9)",
        borderColor: "rgba(0, 221, 255, 1)",
        textStyle: {
          color: "#E0F0FF",
          fontSize: 12,
        },
        formatter: (params) => {
          return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${params.value}`;
        },
      },
      legend: {
        orient: "vertical",
        data: legendData,
        textStyle: {
          color: "#fff",
          fontSize: 13,
        },
        itemWidth: 11,
        itemHeight: 11,
        icon: "rect",
        formatter: function (name) {
          let item = pieData.filter((item) => item.name == name)[0];
          return `${item.name}    ${item.value}`;
        },
        itemGap: 14,
        right: "20%",
        top: "30%", //居右显示
      },
      series: [
        {
          type: "pie",
          center: ["28%", "59%"],
          radius: ["25%", "33%"], // 饼图的内外半径
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          itemStyle: {
            normal: {
              // borderColor: '#fff',
              borderWidth: 2,
            },
            emphasis: {
              // borderColor: '#fff',
              borderWidth: 4,
            },
          },
          labelLine: {
            show: false,
          },
          data: seriesData,
        },
      ],
    };

    return option;
  };

  let option = getPie(data.value);
  echarts.init(initChart.value).dispose();
  echarts.init(initChart.value).setOption(option);
};
</script>

<style lang="less" scoped>
.ChargingGunStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  height: 100%;
  box-sizing: border-box;

  .echart {
    width: 100%;
    height: 400px;
    > img {
      position: absolute;
      height: 170px;
      width: 206px;
      transform: translate(-50%, -50%);
      left: 28%;
      top: 66%;
    }

    .chart-box {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 100%;
      height: 380px;
    }
  }
}
</style>
